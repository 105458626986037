<template>
  <sgv-input-select
    :options="items"
    @input="emitInput"
    v-bind="$attrs"
    :value="value"
    select="value">
    <template slot-scope="option">
      {{option.text}}
    </template>
  </sgv-input-select>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: false
    },
    options: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      selected: null,
      items: [
        {text: 'ทั้งหมด', value: null},
        {text: 'ยกเลิก', value: -1},
        ...this.options
      ]
    }
  },
  methods: {
    emitInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="css" scoped>

</style>
