<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
          <router-link
            class="text-decoration-none text-secondary"
            v-if="$auth.hasRole(`paper:${paperType}:config`)"
            :to="toPaperConfig()">
            <small><fa icon="cog"></fa></small>
          </router-link>
        </h3>
      </div>

      <div class="dashhead-toolbar">
        <div class="dashhead-toolbar-item">
          <Category v-model="selectedCategory"></Category>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <sgv-table
      :rKey="rKey"
      :items="papers"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">
      <template slot-scope="{item, hidden}">
        <tr>
          <td
            v-if="hidden.code"
            @click="toDetail(item.id)"
            class="pointer"
            style="white-space: nowrap;"
            :class="{
              'text-danger': !item.isActive,
              'text-warning': !item.approvedBy
            }">
            {{ item.code }}
          </td>
          <td v-if="hidden.name">
            {{ item.name }}
          </td>
        </tr>
      </template>

      <button
        v-if="$auth.hasRole(`paper:${paperType}:add`)"
        type="button"
        class="btn btn-link text-success"
        slot="action"
        @click="toDetail(0)">
        เพิ่ม
      </button>
    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import Category from '@/views/paper_template/components/Category.vue'
import { LIST_PAPER } from './graph'

export default {
  components: {
    Category
  },
  mixins: [retainMixin],
  props: {
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      papers: [],
      headers: [
        {text: 'รหัส', value: 'code', sort: true, filter: true},
        {text: 'ชื่อ', value: 'name', sort: true, filter: true},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      paperConfigView : `Paper${this.$form.capitalize(this.paperType)}Config`,
      detailView: `Paper${this.$form.capitalize(this.paperType)}Detail`,
      rKey: `Paper${this.$form.capitalize(this.paperType)}List`,
      rFields: ['filter', 'options', 'selectedCategory'],
      selectedCategory: null
    }
  },
  apollo: {
    papers: {
      query () {
        return LIST_PAPER(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          paperType: this.paperType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      return {
        ...v,
        params: {
          ...v.params,
          type: this.paperType,
          isActive: this.selectedCategory !== -1
        }
      }
    },
    toDetail (paperId) {
      this.$router.push({
        name: this.detailView,
        params: {paperId}
      })
    },
    toPaperConfig () {
      return {name: this.paperConfigView}
    },
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  }
}
</script>

<style lang="css" scoped>
</style>
